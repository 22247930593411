import axios from "axios";

import Config from "../../Config";
import Helpers from "../../Helpers";
import { OPEN_VEHICLERETURN_BY_SEARCHPARAM, OPEN_VEHICLERETURN_BY_SEARCHPARAM_SUCCESS, OPEN_VEHICLERETURN_BY_SEARCHPARAM_FAILURE } from "../Search/SearchModule"

import { CHANGE_FEED_USER_MESSAGE_RECEIVED, CHANGE_FEED_MESSAGE_RECEIVED } from "../ChangeFeed/ChangeFeedModule";

export const VEHICLE_RETURNS_SUMMARIES_REQUESTED =
  "VEHICLE_RETURNS/SUMMARIES::LIST_REQUESTED";
export const VEHICLE_RETURNS_SUMMARIES_SUCCESS =
  "VEHICLE_RETURNS/SUMMARIES::LIST_SUCCESS";
export const VEHICLE_RETURNS_SUMMARIES_FAILURE =
  "VEHICLE_RETURNS/SUMMARIES::LIST_FAILURE";

export const VEHICLE_RETURNS_SUMMARIES_DELETE_REQUESTED =
  "VEHICLE_RETURNS/SUMMARIES::DELETE_REQUESTED";

export const VEHICLE_RETURNS_SUMMARIES_DELETE_SUCCESS =
  "VEHICLE_RETURNS/SUMMARIES::DELETE_SUCCESS";

export const VEHICLE_RETURNS_SUMMARIES_DELETE_FAILURE =
  "VEHICLE_RETURNS/SUMMARIES::DELETE_FAILURE";

const INITIAL_STATE = {
  isBusy: false,
  isDone: false,
  isError: false,
  error: null,
  items: [],
  visibleItems: 0
};

const INITIAL_ITEM_STATE = {
  isDeleting: false,
  isDeleted: false,
  isDeletionError: false,
  deletionError: null
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHANGE_FEED_MESSAGE_RECEIVED:
      console.log(CHANGE_FEED_MESSAGE_RECEIVED, payload);
      console.log(state);
      switch (payload.message.feed) {
        case "VehicleReturnOpened":
          let items = [...state.items];

          const openedExistingIndex = items.findIndex(
            i => i.processUid === payload.message.payload.aggregateId
          );
          if (openedExistingIndex >= 0) {
            console.log("item already existing");
            return state;
          }

          var p = {
            processUid: payload.message.payloadRaw.aggregateId,
            processOpenedDate: payload.message.payloadRaw.issuedAt,
            state: 'Neu'
          };

          items.push(p);
          return {
            ...state,
            items: items
          };
        case "ContractDataChanged":
          let cdcitems = [...state.items];
          const existingIndex = cdcitems.findIndex(
            i => i.processUid === payload.message.payload.aggregateId
          );

          if (existingIndex < 0) {
            console.log("item not found");
            return state;
          }

          cdcitems[existingIndex] = {
            ...cdcitems[existingIndex],
            ...payload.message.payload,
            contractNumber: payload.message.payloadRaw.contractNumber,
            contractStart: payload.message.payloadRaw.contractStart,
            contractEnd: payload.message.payloadRaw.contractEnd,
            lesseeName: payload.message.payloadRaw.lesseeFirstName + " " + payload.message.payloadRaw.lesseeLastName
          }

          return {
            ...state,
            items: cdcitems
          };
        case "VehicleDataChanged":
          let vdcitems = [...state.items];
          const vdcexistingIndex = vdcitems.findIndex(
            i => i.processUid === payload.message.payload.aggregateId
          );

          if (vdcexistingIndex < 0) {
            console.log("item not found");
            return state;
          }

          vdcitems[vdcexistingIndex] = {
            ...vdcitems[vdcexistingIndex],
            ...payload.message.payload,
            licensePlateNumber: payload.message.payloadRaw.licensePlateNumber,
            vehicleIdentificationNumber: payload.message.payloadRaw.vehicleIdentificationNumber
          }

          return {
            ...state,
            items: vdcitems
          };
        case "ProcessDeleted":
          let preDeletionItems = [...state.items];

          const existingDeleteIndex = preDeletionItems.findIndex(
            i => i.processUid === payload.message.payload.aggregateId || i.vehicleReturnId === payload.message.payload.aggregateId
          );

          if (existingDeleteIndex < 0) {
            console.log("item to delete was not found: ", payload.message.payload.aggregateId);
            return state;
          }

          console.log("existingDeleteIndex: ", existingDeleteIndex);
          console.log("deleting: ", payload.message.payload.aggregateId);
          console.log("splicing: ", preDeletionItems.map(x => ({ aggregateId: x.aggregateId, processUid: x.processUid, vehicleReturnId: x.vehicleReturnId })));
          preDeletionItems.splice(existingDeleteIndex, 1);
          console.log("spliced: ", preDeletionItems.map(x => ({ aggregateId: x.aggregateId, processUid: x.processUid, vehicleReturnId: x.vehicleReturnId })));

          // preDeletionItems[existingDeleteIndex] = {
          //   ...preDeletionItems[existingDeleteIndex],
          //   ...payload
          // };
          return {
            ...state,
            items: preDeletionItems,
            visibleItems: preDeletionItems.filter(i => i.isDeleted !== true).length
          };
        case "EditorChanged":
          let ecitems = [...state.items];
          const ecexistingIndex = ecitems.findIndex(
            i => i.processUid === payload.message.payload.aggregateId
          );

          if (ecexistingIndex < 0) {
            console.log("item not found");
            return state;
          }

          ecitems[ecexistingIndex] = {
            ...ecitems[ecexistingIndex],
            ...payload.message.payload,
            editorId: payload.message.payloadRaw.userId
          }

          return {
            ...state,
            items: ecitems
          };
        case "RetailerChanged":
          let rcitems = [...state.items];
          const rcexistingIndex = rcitems.findIndex(
            i => i.processUid === payload.message.payload.aggregateId
          );

          if (rcexistingIndex < 0) {
            console.log("item not found");
            return state;
          }

          rcitems[rcexistingIndex] = {
            ...rcitems[rcexistingIndex],
            ...payload.message.payload,
            retailerCompanyName: payload.message.payloadRaw.companyName
          }
          
          return {
            ...state,
            items: rcitems
          };
        default:
          console.log("Event not handled: " + payload.message.feed);
          break;
      }
      return state;
    case CHANGE_FEED_USER_MESSAGE_RECEIVED:
      let items = [...state.items];
      if (payload.message && payload.message.type === "VehicleReturnSummary") {
        const existingIndex = items.findIndex(
          i => i.vehicleReturnId === payload.message.payload.vehicleReturnId
        );
        if (existingIndex < 0) {
          items.push({
            ...INITIAL_ITEM_STATE,
            ...payload.message.payload
          });
        } else {
          items[existingIndex] = {
            ...items[existingIndex],
            ...payload.message.payload,
            isDeleted: payload.message.payload.status === "DELETED"
          };
        }
      }
      return {
        ...state,
        items,
        visibleItems: items.filter(i => i.isDeleted !== true).length
      };
    case VEHICLE_RETURNS_SUMMARIES_REQUESTED:
      return {
        ...state,
        isBusy: payload.isBusy === true,
        isDone: false,
        isError: false,
        error: null
      };
    case VEHICLE_RETURNS_SUMMARIES_SUCCESS:
      const newItems = payload.items.map(i => ({
        ...INITIAL_ITEM_STATE,
        ...i,
        isDeleted: i.state === "DELETED",
        lesseeName: i.lesseeFirstName + " " + i.lesseeLastName
      }));
      return {
        ...state,
        // isBusy: false,
        isDone: true,
        isError: false,
        error: null,
        items: newItems,
        visibleItems: newItems.filter(i => i.isDeleted !== true).length
      };
    case VEHICLE_RETURNS_SUMMARIES_FAILURE:
      return {
        ...state,
        isBusy: false,
        isDone: true,
        isError: true,
        error: payload.error
      };
    case VEHICLE_RETURNS_SUMMARIES_DELETE_REQUESTED:
    case VEHICLE_RETURNS_SUMMARIES_DELETE_SUCCESS:
    case VEHICLE_RETURNS_SUMMARIES_DELETE_FAILURE:
      let preDeletionItems = [...state.items];

      const existingIndex = preDeletionItems.findIndex(
        i => i.processUid === payload.vehicleReturnId
      );

      if (existingIndex < 0) {
        return state;
      }

      preDeletionItems[existingIndex] = {
        ...preDeletionItems[existingIndex],
        ...payload
      };
      return {
        ...state,
        items: preDeletionItems,
        visibleItems: preDeletionItems.filter(i => i.isDeleted !== true).length
      };
    case OPEN_VEHICLERETURN_BY_SEARCHPARAM:
    case OPEN_VEHICLERETURN_BY_SEARCHPARAM_SUCCESS:
    case OPEN_VEHICLERETURN_BY_SEARCHPARAM_FAILURE:
      console.log(type, payload, state);
      return state;
    default:
      return state;
  }
};

export const getVehicleReturnSummaries = (byOwner = false) => dispatch => {
  var endpointUrl = Config.Endpoints.API_VEHICLE_RETURN.Overview.format(
    { byOwner }
  );
  dispatch({
    type: VEHICLE_RETURNS_SUMMARIES_REQUESTED,
    payload: {
      isBusy: true
    }
  });

  axios
    .get(endpointUrl, {
      headers: {
        Authorization: `Bearer ${Helpers.Oidc.accessTokenFactory()}`
      }
    })
    .then(response => {
      dispatch({
        type: VEHICLE_RETURNS_SUMMARIES_SUCCESS,
        payload: {
          items: response.data
        }
      });
    })
    .catch(err => {
      dispatch({
        type: VEHICLE_RETURNS_SUMMARIES_FAILURE,
        payload: {
          error: err
        }
      });
    });
};

export const deleteVehicleReturn = vehicleReturnId => dispatch => {
  var endpointUrl = Config.Endpoints.API_VEHICLE_RETURN.DeleteVehicleReturn.format(
    {
      id: vehicleReturnId
    }
  );

  dispatch({
    type: VEHICLE_RETURNS_SUMMARIES_DELETE_REQUESTED,
    payload: {
      vehicleReturnId,
      isDeleting: true,
      error: null,
      isError: false
    }
  });

  axios
    .delete(endpointUrl, {
      headers: {
        Authorization: `Bearer ${Helpers.Oidc.accessTokenFactory()}`
      }
    })
    .then(_ => {
      dispatch({
        type: VEHICLE_RETURNS_SUMMARIES_DELETE_SUCCESS,
        payload: {
          vehicleReturnId,
          isDeleting: false,
          isError: false,
          isDeleted: true,
          error: null
        }
      });
    })
    .catch(err => {
      dispatch({
        type: VEHICLE_RETURNS_SUMMARIES_DELETE_FAILURE,
        payload: {
          vehicleReturnId,
          isDeleting: false,
          isError: true,
          isDeleted: false,
          error: err
        }
      });
    });
};
