import { CHANGE_FEED_MESSAGE_RECEIVED } from "../ChangeFeed/ChangeFeedModule";

const INITIAL_STATE = {
  events: []
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  // console.log(action.type);
  switch (action.type) {
    case CHANGE_FEED_MESSAGE_RECEIVED:
      let events = state.events
      if (events.length >= 8) { events.shift(); }
      return Object.assign({}, state, {
        events: events.concat(action.payload)
      });
    default:
      return state;
  }
};
