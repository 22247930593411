import React from 'react';
import ContractData from './ContractData';
import VehicleData from './VehicleData';
import VehicleLocation from './VehicleLocation';
import Grid from '@material-ui/core/Grid'

const ProcessDetails = props => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <ContractData {...props} />
        </Grid>
        <Grid item xs={12}>
          <VehicleData {...props} />
        </Grid>        
        <Grid item xs={12}>
          <VehicleLocation {...props} />
        </Grid>
      </Grid >
    </div >
  );
};

export default ProcessDetails;
