import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import "./LicensPlateNumber.css";

const useStyles = makeStyles(theme => ({
  licensePlateNumber_german: {
    fontFamily: "Unica One",
    fontSize: "1.5em",
    textTransform: "uppercase",
    border: "1px solid black",
    borderRadius: "0.1em",
    padding: "0.05em 0.2em",
    paddingLeft: "0.65em",
    lineHeight: "1em",
    display: "inline-block"
  },
  licensePlateNumber_german__part1: {},
  licensePlateNumber_german__separator: {
    marginLeft: "0.1em",
    marginRight: "0.1em"
  },
  licensePlateNumber_german__part2: {},
  licensePlateNumber_german__part3: {
    marginLeft: "0.1em"
  }
}));

const GermanLicensePlate = ({ parts }) => {
  const classes = useStyles();

  return (
    <section className="licensePlateNumber">
      <article
        className={`${classes.licensePlateNumber_german} licensePlateNumber-german`}
      >
        {(parts.length !== 4 && <code>Invalid LicensePlateNumber</code>) || (
          <React.Fragment>
            <span className={classes.licensePlateNumber_german__part1}>
              {parts[1]}
            </span>
            <span className="licensePlateNumber-german-part__separator"></span>
            <span className={classes.licensePlateNumber_german__part2}>
              {parts[2]}
            </span>
            <span className={classes.licensePlateNumber_german__part3}>
              {parts[3]}
            </span>
          </React.Fragment>
        )}
      </article>
    </section>
  );
};

const parseLicensePlateNumber = (subject, type) => {
  const patterns = {
    de: {
      aliases: ["german", "germany", "de"],
      pattern: /([A-Z]{1,3})\W+([A-Z]{1,2})\s*([0-9]{1,4})/i
    }
  };

  let result = {
    parts: [subject],
    isValidFor: false
  };

  if (subject && subject.length) {
    for (var key in patterns) {
      if (!patterns.hasOwnProperty(key)) {
        continue;
      }

      const current = patterns[key];

      if (
        key === type ||
        (current.aliases && current.aliases.indexOf(key) >= 0) ||
        (!type && current.pattern.test(subject))
      ) {
        const pattern = current.pattern;
        result.parts = subject.match(pattern);

        result.isValidFor =
          result.parts.length === 4 &&
          result.parts[0] &&
          result.parts[1] &&
          result.parts[2] &&
          result.parts[3]
            ? key
            : false;
      }

      if (result.isValidFor !== false) {
        break;
      }
    }
  }

  return result;
};

export default ({ type, value }) => {
  const parsed = parseLicensePlateNumber(value, type);

  switch (parsed.isValidFor) {
    case "de":
      return <GermanLicensePlate parts={parsed.parts} />;
    default:
      return <span className="LicensePlateNumber__unformatted">{value}</span>;
  }
};
