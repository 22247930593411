import { createUserManager } from "redux-oidc";

let _userManager = null;

const userManager = () => {
  if (_userManager !== null) {
    return _userManager;
  }

  const Config = window.__learoy__config;
  console.log("userManager: ", window.location);
  const userManagerConfig = {
    client_id: "learoy-app",
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }/authcallback`,
    response_type: "token id_token",
    scope: "openid profile",
    authority: Config.Endpoints.OIDC_AUTH_ENDPOINT.$root,
    silent_redirect_uri: `${window.location.protocol}//${
      window.location.hostname
    }${
      window.location.port ? `:${window.location.port}` : ""
    }/silent_renew.html`,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: true
  };

  _userManager = createUserManager(userManagerConfig);
  return _userManager;
};

export default userManager;
