import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import SearchForm from "./SearchForm";
import { openVehicleReturnBySearchParam } from "./SearchModule";
import { subscribeToFeed } from "../ChangeFeed/ChangeFeedModule";

export class Search extends Component {
  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(searchParam) {
    const { openVehicleReturnBySearchParam } = this.props;

    openVehicleReturnBySearchParam(searchParam);
  }

  componentDidUpdate(prevProps) {
    const {
      push,
      subscribeToFeed,
      search: {
        isSearching,
        isSuccess,
        last: { vehicleReturnId, isExisting }
      }
    } = this.props;

    if (
      prevProps.search.isSearching &&
      isSearching === false &&
      vehicleReturnId
    ) {
      subscribeToFeed(`VehicleReturn-${vehicleReturnId}`, msg => {
        console.warn("GOT", msg);
      });
    }
    if (
      isSuccess &&
      isSuccess !== prevProps.search.isSuccess &&
      vehicleReturnId
    ) {
      if (isExisting) {
        push(`/vehiclereturns/${vehicleReturnId}`);
      } else {
        push(`/vehiclereturns/${vehicleReturnId}?pending=true`);
      }
    }
  }

  render() {
    const { props } = this;
    const { openVehicleReturnBySearchParam } = props;
    return (
      <SearchForm
        {...props.search}
        classes={props.classes}
        onSearch={openVehicleReturnBySearchParam}
      />
    );
  }
}

const mapStateToProps = state => ({
  search: state.search
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openVehicleReturnBySearchParam,
      push,
      subscribeToFeed
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
