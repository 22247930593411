import React, { Component } from "react";

import { connect } from "react-redux";
import { push } from "connected-react-router";

import { bindActionCreators } from "redux";

import OverviewList from "./OverviewList";

import {
  getVehicleReturnSummaries,
  deleteVehicleReturn
} from "../VehicleReturnsModule";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.editItem = this.editItem.bind(this);
  }
  componentDidMount() {
    const { getVehicleReturnSummaries } = this.props;

    getVehicleReturnSummaries();
  }

  editItem(vehicleReturnId) {
    const { push } = this.props;
    push(`/vehiclereturns/${vehicleReturnId}`);
  }

  render() {
    const { deleteVehicleReturn } = this.props;
    return (
      <OverviewList
        {...this.props}
        editItemAction={this.editItem}
        deleteItemAction={deleteVehicleReturn}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    isBusy: state.vehicleReturns.isBusy,
    isDone: state.vehicleReturns.isDone,
    isError: state.vehicleReturns.isError,
    items: state.vehicleReturns.items.filter(i => i.isDeleted !== true),
    visibleItems: state.vehicleReturns.visibleItems
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getVehicleReturnSummaries,
      deleteVehicleReturn,
      push
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview);
