import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { initializeChangeFeed } from "./ChangeFeedModule";
// import { initializeChangeFeed } from "../../NotificationFeed";

export class ChangeFeedHost extends Component {
  constructor(props) {
    super(props);
    this.ensureConnection = this.ensureConnection.bind(this);
  }

  componentDidMount() {
    this.ensureConnection();
  }

  componentDidUpdate() {
    this.ensureConnection();
  }

  ensureConnection() {
    const { initializeChangeFeed, isConnected, user } = this.props;
    const Config = window.__learoy__config;
    if (process.env.NODE_ENV !== "development" || ( (user && !user.expired) && !isConnected)) {
      initializeChangeFeed(Config.Endpoints.API_VEHICLE_RETURN_HUB.$root);
    }
  }
  
  render() {
    // const { props } = this;
    return (
      <article className="changeFeedHost">
        {/* {<code>{JSON.stringify(props)}</code>} */}
      </article>
    );
  }
}

const mapStateToProps = state => ({
  isConnected: state.changeFeed.isConnected,
  user: state.oidc.user,
  isLoadingUser: state.oidc.isLoadingUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      initializeChangeFeed
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeFeedHost);
