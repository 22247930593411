import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";

import AppMenu from "./AppMenu";

const AppBar = ({
  isAuthenticated,
  user,
  overviewBadge,
  notificationsBadge,
  push
}) => (
  <React.Fragment>
    <AppMenu
      isAuthenticated={isAuthenticated}
      user={user}
      overviewBadge={overviewBadge}
      notificationsBadge={notificationsBadge}
      overviewAction={() => {
        push("/vehicleReturns/");
      }}
    />
  </React.Fragment>
);

const getNotificationsUserChanges = changes => {
  let count = 0;

  count = changes.filter(change => change.isNew === true).length;

  return count > 0 ? count : null;
};

const mapStateToProps = state => ({
  isAuthenticated: !state.oidc.isLoadingUser && state.oidc.user,
  user: state.oidc.user,
  overviewBadge: state.vehicleReturns.visibleItems,
  notificationsBadge: getNotificationsUserChanges(state.changeFeed.userChanges)
});
const mapDispatchToProps = dispath =>
  bindActionCreators(
    {
      push
    },
    dispath
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AppBar)
);
