import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";

import { loadUser } from "redux-oidc";

// import notification from './NotificationMiddleware'
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";

import rootReducer from "./rootReducer";
import userManager from "../Oidc/userManager";

// import { init } from "../Config";
import Helpers from "../Helpers";

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [thunk, /*notification,*/ routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
  
);

loadUser(store, userManager()).then(user => {
  if (user) {
    Helpers.Oidc.updateAccessToken(user.access_token);
  }
});

export default store;
