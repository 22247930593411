import * as momentTz from 'moment-timezone';

// Fix for unit tests and ES5/ES6 import problems
let moment = momentTz;
if ('default' in moment) {
  moment = moment['default'];
}

/**
 * Try to parse the given dateString by using the given format using moment
 *
 * @param {string} dateString  String of date/time informations which should be parsed
 * @param {string} format      Format of the given dateString to create a valid moment object
 * @returns {moment}           Returns a moment-timezone object representing the given time
 *
 * @see https://momentjs.com/docs/#/parsing/string-format/
 */
export function parse(dateString, format) {
  if (!dateString) {
    return now();
  }

  return format ? moment(dateString, format) : moment(dateString);
}

export function now() {
  return moment();
}

export const DefaultDisplayDateFormat = 'D.M.YYYY';
export const DefaultEditDateFormat = 'DD.MM.YYYY';

export const DefaultDisplayTimeFormat = 'HH:mm';
export const DefaultEditTimeFormat = 'HH:mm';

export const DefaultDisplayFormat = `${DefaultEditDateFormat} ${DefaultEditTimeFormat}`;

function formatMoment(subject, format = DefaultDisplayFormat) {
  if (!subject) {
    return '—';
  }

  if (subject.isValid && subject.isValid()) {
    return subject.format(format);
  }

  return null;
}

export function format(subject, format = DefaultDisplayFormat) {
  if (!subject) {
    return formatMoment(subject, format);
  }

  if (!moment.isMoment(subject)) {
    return formatMoment(parse(subject), format);
  }

  return formatMoment(subject, format);
}

export function displayDateRange(subject1, subject2, invalidReplacement = '?') {
  return `${editDate(subject1) || invalidReplacement} - ${editDate(subject2) || invalidReplacement}`;
}

export function displayShortDate(subject) {
  return format(subject, DefaultDisplayDateFormat);
}

export function displayShortTime(subject) {
  return format(subject, DefaultDisplayTimeFormat);
}

export function display(subject) {
  return format(subject, DefaultDisplayFormat);
}

export function editDate(subject) {
  return format(subject, DefaultEditDateFormat);
}

export function editTime(subject) {
  return format(subject, DefaultEditTimeFormat);
}

export function addDays(subject, days) {
  let dateToAdd = subject;
  if (!moment.isMoment(dateToAdd)) {
    dateToAdd = parse(dateToAdd);
  }

  const dateStringWithAddedDays = dateToAdd.add(days, 'days').format();
  return parse(dateStringWithAddedDays);
}

export const displayDate = editDate;
export const displayTime = editTime;
