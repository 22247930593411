import React from "react";
import { connect } from "react-redux";
import "./code.css";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const mapStateToProps = state => {
  return { events: state.eventFeed.events };
};

const ConnectedEventFeed = ({ events }) => (
  <ul>
    {events.map((notification, index) => (
      <ExpansionPanel className="my_code" key={index}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className="my_code">
          {<code className="my_code">{notification.message.type}</code>}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="my_code">
          {<code className="my_code">{JSON.stringify(notification.message.payloadRaw)}</code>}
      </ExpansionPanelDetails>
  </ExpansionPanel>
    ))}
  </ul>
);

const EventFeed = connect(mapStateToProps)(ConnectedEventFeed);

export default EventFeed;
