import React from "react";

import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  vehicleIdentificationNumber: {},
  vehicleIdentificationNumber_label: {
    marginRight: "0.15em"
  },
  vehicleIdentificationNumber_value: {
    fontFamily: '"Courier New", monospace',
    padding: "0.15em 0.35em"
  }
}));

export default ({ value, useLabel }) => {
  const classes = useStyles();

  const label =
    useLabel === false ? "" : typeof useLabel === "String" ? useLabel : "FIN";

  return (
    <div>
      <article className={classes.vehicleIdentificationNumber}>
        {useLabel !== false && (
          <span className={classes.vehicleIdentificationNumber_label}>
            {label}:
        </span>
        )}
        <span className={classes.vehicleIdentificationNumber_value}>{value}</span>
      </article>
    </div>
  );
};
