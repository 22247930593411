import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { useStyles } from '../theme';

export default ({ header, body, sidebar, isShowSidebar }) => {
  const classes = useStyles();
  const bodyColumns = {
    xs: 12,
    sm: isShowSidebar ? 8 : 12
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {header && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>{header}</Paper>
          </Grid>
        )}
        {body && (
          <Grid item xs={bodyColumns.xs} sm={bodyColumns.sm}>
            <Paper className={classes.paper}>{body}</Paper>
            <Paper>
              {/* <NotificationFeed classes={classes} notifications={notifications}/> */}
            </Paper>
          </Grid>
        )}
        {sidebar && isShowSidebar && (
          <Grid item xs={12} sm={4}>
            <Paper className={classes.paper}>{sidebar}</Paper>
          </Grid>
        )}
        {/* <Grid item xs={12} sm={4}>
          <Footer />
        </Grid> */}
      </Grid>
    </div>
  );
};
