import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from 'clsx';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { DisplayFor } from '../../../../../Helpers';

class VehicleData extends React.Component {
  render() {
    const { vehicleReturnData: { vehicleModel = '', vehicleModelKey = '', agreedMileage = '' } } = this.props;
    return (
      <Card className={clsx(this.props.classes.root, this.props.className)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item >
              <DirectionsCarIcon />
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="h5" >Fahrzeug</Typography>
            </Grid>
          </Grid>
          <Divider style={{marginBottom:'16px'}}/>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} >
              <DisplayFor labelClassName="u-mb-xsmall" labelText="Modell" value={vehicleModel} dataKey="vehicleModel" />
            </Grid>
            <Grid item md={6} xs={12}>
              <DisplayFor labelClassName="u-mb-xsmall" labelText="Modellschlüssel" value={vehicleModelKey} dataKey="vehicleModelKey" />
            </Grid>
            <Grid item md={6} xs={12}>
              <DisplayFor labelClassName="u-mb-xsmall" labelText="Laufleistung" value={agreedMileage + " km"} dataKey="agreedMileage" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleData);
