import React, { Component } from "react";

import InputBase from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

//import "./SearchForm.css";

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParam: "",
      isShowError: false
    };

    this.onChangeSearchParam = this.onChangeSearchParam.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.searchParam !== prevState.searchParam) {
      // validate
      this.setState({
        isValidSearchParam:
          this.isValidSearchParam(this.state.searchParam) === true
      });
    }

    if (
      prevProps.isSearching &&
      prevProps.isSearching !== this.props.isSearching
    ) {
      this.setState({
        isShowError: true
      });
    }
  }

  isValidSearchParam(searchParam) {
    if (!searchParam) {
      return "INVALID SEARCH PARAMETER";
    }

    const isValidLicensePlateNumberCheck = /^[A-Z]{2,3}\W+[A-Z]{1,2}\W*[0-9]{1,3}$/gi.test(
      searchParam
    )
      ? undefined
      : "NO VALID LICENSEPLATENUMBER";

    const isValidVehicleIdentificationNumberCheck =
      "INVALID VEHICLE IDENTIFICATION NUMBER";

    const isValidContractNumberCheck = "INVALID CONTRACT NUMBER";

    return (
      isValidLicensePlateNumberCheck === undefined ||
      isValidVehicleIdentificationNumberCheck === undefined ||
      isValidContractNumberCheck === undefined
    );
  }

  onChangeSearchParam(evt) {
    var value = evt.target.value;
    evt.preventDefault();
    if (value !== this.state.searchParam) {
      this.setState({
        searchParam: value,
        isShowError: false
      });
    }
  }

  onSearch(evt) {
    const { onSearch } = this.props;

    const { searchParam } = this.state;

    evt.preventDefault();

    onSearch(searchParam);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="search"
              onClick={() => this.sendMessage()}>
              <SearchIcon />
            </IconButton>
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            onKeyUp={ev => {
              ev.preventDefault();
              if (ev.keyCode === 13) this.props.onSearch(this.state.searchParam);
            }}
            onChange={this.onChangeSearchParam}
            maxLength={18}
            disabled={this.props.isSearching}
            value={this.state.searchParam}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default SearchForm;
