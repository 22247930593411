import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { OidcProvider } from "redux-oidc";

import store, { history } from "./store";

import userManager from "./Oidc/userManager";

// import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { init } from "./Config";

init(() => {
  ReactDOM.render(
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager()}>
        <ConnectedRouter history={history}>
          <div>
            <App />
          </div>
        </ConnectedRouter>
      </OidcProvider>
    </Provider>,
    document.getElementById("root")
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
