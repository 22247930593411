import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateVehicleLocationData, submitVehicleLocationData } from "../../../VehicleReturnModule";
import clsx from 'clsx';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  CardActions,
  Button,
  Typography
} from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';

class VehicleLocation extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
    const { updateVehicleLocationData, vehicleReturnData } = this.props;
    updateVehicleLocationData({ ...vehicleReturnData.vehicleLocationData, [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log("handleSubmit");
    const { submitVehicleLocationData } = this.props;
    submitVehicleLocationData();
  }

  render() {
    if (!this.props.vehicleReturnData.vehicleLocationData) {
      return (<div />);
    }

    const { description = '', adress = '', zip = '', city = '' } = this.props.vehicleReturnData.vehicleLocationData;
    return (
      <Card className={clsx(this.props.classes.root, this.props.className)}>
        <form autoComplete="off" noValidate onSubmit={this.handleSubmit} >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item >
                <LocationOn />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="h5" >Fahrzeugstandort</Typography>
              </Grid>
            </Grid>
            <Divider style={{marginBottom:'16px'}}/>
            <Grid container spacing={3}>
              <Grid item xs={12} >
                <TextField id="description" name="description" label="Bezeichnung" value={description} margin="normal" variant="outlined" onChange={this.handleChange}
                  fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField id="adress" name="adress" label="Straße und Hausnr." value={adress} margin="normal" variant="outlined" onChange={this.handleChange}
                  fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField id="zip" name="zip" label="PLZ" value={zip} margin="normal" variant="outlined" onChange={this.handleChange}
                  fullWidth />
              </Grid>
              <Grid item xs={8}>
                <TextField id="city" name="city" label="Ort" value={city} margin="normal" variant="outlined" onChange={this.handleChange}
                  fullWidth />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" type="submit">
              Save location data
          </Button>
          </CardActions>
        </form>
      </Card>);
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateVehicleLocationData,
      submitVehicleLocationData
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleLocation);
