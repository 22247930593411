import * as signalR from "@aspnet/signalr";

const CHANGE_FEED_INIT = "CHANGE_FEED/init";
const CHANGE_FEED_INIT_CONNECTED = "CHANGE_FEED/init::connected";
// const CHANGE_FEED_INIT_DISCONNECTED = "CHANGE_FEED/init::disconnected";
const CHANGE_FEED_INIT_RECONNECTING = "CHANGE_FEED/init::reconnecting";
// const CHANGE_FEED_INIT_RECONNECTED = "CHANGE_FEED/init::reconnected";
const CHANGE_FEED_INIT_FAILURE = "CHANGE_FEED/init::failure";

const CHANGE_FEED_SUBSCRIBED = "CHANGE_FEED/FEED::subscribed";
const CHANGE_FEED_UNSUBSCRIBED = "CHANGE_FEED/FEED::unsubscribed";

export const CHANGE_FEED_MESSAGE_RECEIVED = "CHANGE_FEED/MESSAGE::received";
export const CHANGE_FEED_USER_MESSAGE_RECEIVED =
  "CHANGE_FEED/USER_MESSAGE::received";

export const CHANGE_FEED_USER_MESSAGES_READ = "CHANGE_FEED/USER_MESSAGES::read";
const Config = window.__learoy__config;
let connection = null;

let knownFeeds = [];

let callbacks = [
  {
    feed: "*",
    callback: message => {
      // console.info(`ReceivedChange`, message);
    }
  }
];

const INITIAL_STATE = {
  isConnected: false,
  isConnecting: false,
  isError: false,
  connectionId: null,
  reason: null,
  feeds: {},
  userChanges: []
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  // console.log(action.type, action.payload);
  switch (action.type) {
    case CHANGE_FEED_INIT:
    case CHANGE_FEED_INIT_CONNECTED:
    case CHANGE_FEED_INIT_RECONNECTING:
    case CHANGE_FEED_INIT_FAILURE:
      return {
        ...state,
        ...action.payload
      };
    case CHANGE_FEED_SUBSCRIBED:
      return {
        ...state,
        feeds: {
          ...{
            [action.payload.feedName]: {
              version: -1,
              changes: []
            }
          },
          ...state.feeds
        }
      };

    case CHANGE_FEED_UNSUBSCRIBED:
      let feeds = { ...state.feeds };

      delete feeds[action.payload.feedName];

      return {
        ...state,
        feeds
      };

    case CHANGE_FEED_MESSAGE_RECEIVED:
    case CHANGE_FEED_USER_MESSAGE_RECEIVED:
      const feedName = action.payload.feed;
      const message = action.payload.message;
      // console.log(CHANGE_FEED_MESSAGE_RECEIVED, action.payload);
      if (
        !(
          state.feeds.hasOwnProperty(feedName) ||
          action.payload.isUserMessage === true
        )
      ) {
        return state;
      }

      let currentFeeds = {
        [feedName]: {
          version: -1,
          changes: []
        },
        ...state.feeds
      };

      const currentVersion = currentFeeds[feedName].version;
      let changes = currentFeeds[feedName].changes;

      const change = {
        isUserMessage: action.payload.isUserMessage === true,
        ...message
      };

      changes.push(change);

      const userChanges = state.userChanges;

      if (change.isUserMessage && message.payload.version >= 0) {
        userChanges.push({
          ...change,
          isNew: true,
          queueTime: Date.now()
        });
      }

      return {
        ...state,
        feeds: {
          ...state.feeds,
          ...{
            [feedName]: {
              version: currentVersion + action.payload.incrementVersion,
              changes
            }
          },
          userChanges
        }
      };
    case CHANGE_FEED_USER_MESSAGES_READ:
      return {
        ...state,
        userChanges: state.userChanges.map(change => {
          return {
            ...change,
            isNew: change.queueTime >= action.payload.timestamp
          };
        })
      };
    default:
      return state;
  }
};

/**
 *
 * @param {*} feed
 * @param {*} callback
 * @deprecated
 */
export const subscribeFeed = (feed, callback) => {
  if (
    callbacks.findIndex(
      item => item.callback === callback && item.feed === feed
    ) < 0
  ) {
    connection.invoke("Subscribe", feed);
    callbacks.push({ feed, callback });
  }
};

export const subscribeToFeed = feedName => dispatch => {
  if (knownFeeds.indexOf(feedName) < 0) {
    knownFeeds.push(feedName);
    dispatch({
      type: CHANGE_FEED_SUBSCRIBED,
      payload: {
        feedName: feedName
      }
    });
  }
};

export const unsubscribeFromFeed = feedName => dispatch => {
  if (knownFeeds.indexOf(feedName) >= 0) {
    knownFeeds = knownFeeds.filter(kf => kf === feedName);
    dispatch({
      type: CHANGE_FEED_UNSUBSCRIBED,
      payload: {
        feedName: feedName
      }
    });
  }
};

/**
 *
 * @param {*} feed
 * @param {*} callback
 * @deprecated
 */
export const unsubscribeFeed = (feed, callback) => {
  callbacks = callbacks.filter(item => {
    connection.invoke("Unsubscribe", feed);
    return item.callback === callback && item.feed === feed;
  });
};

export const initializeChangeFeed = (accessToken, user, group) => {
  console.log("initializeChangeFeed - Config: ", Config);
  return dispatch => {
    if (
      connection === null ||
      connection.state !== signalR.HubConnectionState.Connected
    ) {
      connection = new signalR.HubConnectionBuilder()
        .withUrl(Config.Endpoints.API_VEHICLE_RETURN_HUB.$root)
        .configureLogging(signalR.LogLevel.Information)
        // .withAutomaticReconnect()
        .build();
      console.log("start connection");
      connection
        .start()
        .catch(reason => {
          dispatch({
            type: CHANGE_FEED_INIT_FAILURE,
            payload: {
              isConnected:
                connection.state === signalR.HubConnectionState.Connected,
              isConnecting:
                connection.state === signalR.HubConnectionState.Connecting,
              isError: true,
              reason
            }
          });
        });

      dispatch({
        type: CHANGE_FEED_INIT,
        payload: {
          isConnected:
            connection.state === signalR.HubConnectionState.Connected,
          isConnecting:
            connection.state === signalR.HubConnectionState.Connecting,
          isError: false,
          reason: null
        }
      });      
    }

    connection.on("ReceiveChange", (feed, message) => {
      // console.log("ReceiveChange with dispatch");
      dispatch({
        type: CHANGE_FEED_MESSAGE_RECEIVED,
        payload: {
          feed,
          message,
          incrementVersion: 1
        }
      });
    });

    connection.on("ReceiveUserChange", (feed, message) => {
      console.info(`call handlers for: ${feed}`, message);
      // callbacks.forEach(handler => {
      //   if (handler.feed === feed || handler.feed === "*") {
      //     handler.callback(message);
      //   }
      // });
      dispatch({
        type: CHANGE_FEED_USER_MESSAGE_RECEIVED,
        payload: {
          feed,
          isUserMessage: true,
          message,
          incrementVersion: 1
        }
      });
    });
  };
};

export const readUserChanges = () => ({
  type: CHANGE_FEED_USER_MESSAGES_READ,
  payload: {
    timestamp: Date.now()
  }
});
