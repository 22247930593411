import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";

import StageGrid from "./StageGrid";

const Stage = ({ header, body, sidebar, isShowSidebar }) => (
  <React.Fragment>
    <StageGrid
      header={header}
      body={body}
      sidebar={sidebar}
      isShowSidebar={isShowSidebar}
    />
  </React.Fragment>
);

const mapStateToProps = state => ({
  isShowSidebar: false
});
const mapDispatchToProps = dispath =>
  bindActionCreators(
    {
      push
    },
    dispath
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stage);
