import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from 'clsx';
import { DisplayFor } from '../../../../../Helpers';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

class ContractData extends React.Component {
  render() {
    const { vehicleReturnData: { lesseeFirstName = '', lesseeLastName = '', contractType = '', processType = '', contractDuration = 0 } } = this.props;
    return (
      <Card className={clsx(this.props.classes.root, this.props.className)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item >
              <LibraryBooksIcon />
            </Grid>
            <Grid item>
              <Typography gutterBottom variant="h5" >Vertrag</Typography>
            </Grid>
          </Grid>
          <Divider style={{marginBottom:'16px'}} />
          <Grid container spacing={3} m={6}>
            <Grid item md={6} xs={12} >
              <DisplayFor labelClassName="u-mb-xsmall" labelText="Vorname" value={lesseeFirstName} dataKey="lesseFirstName" />
            </Grid>
            <Grid item md={6} xs={12}>
              <DisplayFor labelClassName="u-mb-xsmall" labelText="Nachname" value={lesseeLastName} dataKey="lesseeLastName" />
            </Grid>
            <Grid item md={6} xs={12}>
              <DisplayFor labelClassName="u-mb-xsmall" labelText="Vertragsform" value={contractType === "3" ? "Kilometerleasing" : "Unbekannt"} dataKey="contractType" />
            </Grid>
            <Grid item md={6} xs={12}>
              <DisplayFor labelClassName="u-mb-xsmall" labelText="Vermarktung durch" value={processType === "vwldistribution" ? "VWLeasing / VTI" : "Handel"} dataKey="marketingType" />
            </Grid>
            <Grid item md={6} xs={12}>
              <DisplayFor labelClassName="u-mb-xsmall" labelText="Laufzeit" value={contractDuration + " Monate"} dataKey="duration" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractData);
