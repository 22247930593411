import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getVehicleReturnSummary } from "../VehicleReturnModule";
import { Grid } from '@material-ui/core';
import { ProcessSummary, ProcessDetails } from './components';

import { useStyles } from '../../theme';

const Account = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={4}
        >
          <ProcessSummary {...props} showDocuments={props.showDocuments} classes={classes}/>
        </Grid>
        <Grid
          item
          xs={8}
        >
          <ProcessDetails {...props} classes={classes} />
        </Grid>
      </Grid>
    </div>
  )
};

class AccountConnected extends Component {
  constructor(props) {
    super(props);
    this.showDocuments = this.showDocuments.bind(this);
  }

  componentDidMount() {
    const {
      initialPending,
      getVehicleReturnSummary,
      vehicleReturnId
    } = this.props;

    if (initialPending === false) {
      getVehicleReturnSummary(vehicleReturnId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isPending,
      push,
      getVehicleReturnSummary,
      vehicleReturnId
    } = this.props;

    if (prevProps.vehicleReturnId !== vehicleReturnId) {
      getVehicleReturnSummary(vehicleReturnId);
    }
    else if (isPending === false && prevProps.isPending === true && vehicleReturnId) {
      getVehicleReturnSummary(vehicleReturnId);
      push(this.props.location.pathname);
    }
  }

  render() {
    const {
      vehicleReturnId,
      isPending,
      vehicleReturnData,
      isLoadingVehicleReturnData
    } = this.props;

    const isValid = vehicleReturnId === vehicleReturnData.vehicleReturnId;
    return (
      <Account
        vehicleReturnId={vehicleReturnId}
        pending={isPending}
        vehicleReturnData={vehicleReturnData}
        isValid={isValid}
        isLoadingVehicleReturnData={isLoadingVehicleReturnData}
        showDocuments={this.showDocuments}
      />
    );
  }


  showDocuments() {
    // const { processUid, showModal, hideModal, processFileList } = this.props;
    alert("Documents");
    // showModal('Dokumente', modalData, null, 'ProcessFileList', hideModal);
  }
}

const evaluatePendingState = (initialPending, feeds, vehicleReturnId) => {
  if (initialPending !== true) {
    return false;
  }

  if (!vehicleReturnId) {
    return true;
  }

  const expectedFeed = `VehicleReturn-${vehicleReturnId}`;

  if (!feeds.hasOwnProperty(expectedFeed)) {
    return true;
  }

  const relevantChanges = feeds[expectedFeed].changes.filter(change => {
    return (
      change.type === "VehicleReturnLookup" &&
      change.payload.vehicleReturnId === vehicleReturnId
    );
  });

  return !relevantChanges || relevantChanges.length === 0;
};

const getInitialPendingFromState = state => {
  return state.router.location.search &&
    state.router.location.search.indexOf("pending=true") >= 0
    ? true
    : false;
};

const mapStateToProps = (state, ownProps) => ({
  vehicleReturnId: ownProps.match.params.id,
  initialPending: getInitialPendingFromState(state),
  isPending: evaluatePendingState(
    getInitialPendingFromState(state),
    state.changeFeed.feeds,
    ownProps.match.params.id
  ),
  vehicleReturnData: state.currentVehicleReturn.current,
  isLoadingVehicleReturnData: state.currentVehicleReturn.isBusy
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      push,
      getVehicleReturnSummary
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountConnected)
);