import { fade, makeStyles } from '@material-ui/core/styles';
const drawerWidth = 240;
export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // padding: theme.spacing(4)
    },appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#1976d2'
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    navBar: {
        top: 10
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    homeButton: {
        marginRight: theme.spacing(2),
    },
    documentButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        align: 'left',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        zIndex: 100,
        // pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    profileIcon: {
        // Aligns the content of the button vertically.
        flexDirection: 'column'
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 200,
            // '&:focus': {
            //     width: 200,
            // },
        },
    },
    table: {
        minWidth: 800,
    },
    paper: {
        padding: theme.spacing(2),
        //textAlign: "left",
        color: theme.palette.text.primary
    },
    myCode: {
        fontFamily: ['Courier', 'New Courier', 'monospace'],
        fontSize: 30,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        backgroundColor: '#333333',
        color: 'green',
        borderRadius: 5,
        margin: theme.spacing(1, 1, 1, 7),
    },
    footer: {
        padding: 5,
        position: "absolute",
        textAlign: "right",
        left: 15,
        bottom: 10,
        right: 15
    },
    details: {
        display: 'flex'
    },
    avatar: {
        marginLeft: 'auto',
        height: 110,
        width: 100,
        flexShrink: 0,
        flexGrow: 0,
        backgroundColor: '#1976d2'
    },
    progress: {
        marginTop: theme.spacing(2)
    },
    uploadButton: {
        marginRight: theme.spacing(2)
    },
    busyAnimation: {
        position: "absolute",
        width: "100%",
        height: "100%",
        minHeight: "250px",
        textAlign: "center",
        backgroundColor: fade('#000000', 0.1),
        zIndex: 999
    },
    divider: {
        margin: theme.spacing(4),
        backgroundColor: 'FF0000'
    },
    
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
