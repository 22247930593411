import React from 'react';

import PropTypes from 'prop-types';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import { DisplayTextFor } from './index';

/**
 * DisplayFor component to render a label and value.
 *
 * @export
 * @class DisplayFor
 * @extends {React.Component}
 */
class DisplayFor extends React.Component {
  static defaultProps = {
    labelLayout: '2/5',
    contentLayout: '3/5',
  };

  /**
   * The prop-types
   *
   * @static
   * @memberof DisplayFor
   */
  static propTypes = {
    labelText: PropTypes.string.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    title: PropTypes.string,
    labelLayout: PropTypes.string,
    contentLayout: PropTypes.string,
  };

  /**
   * The render
   *
   * @returns
   * @memberof DisplayFor
   */
  render() {
    const {
      className,
      labelText,
      value,
      children,
      labelLayout,
      contentLayout,
      labelClassName,
      contentClassName,
      title,
    } = this.props;
    return (
      <section className={`o-layout ${className || ''}`}>
        <article className={`o-layout__item u-${labelLayout} ${labelClassName || ''}`}>
          <Typography color="inherit" variant="caption">
            {labelText}
          </Typography>
          {title && [<span key="0">&#160;</span>,
          <Tooltip title={title} aria-label="info">
            <InfoOutlinedIcon />
          </Tooltip>]}
        </article>
        <article className={`o-layout__item u-${contentLayout} ${contentClassName || ''}`}>
          {children}
          {(!children || children.length === 0) && <Typography color="inherit" variant="body1">{DisplayTextFor(value)}</Typography>}
        </article>
      </section>
    );
  }
}
export default DisplayFor;
