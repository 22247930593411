let OidcStore = {
  currentAccessToken: null
};

export const accessTokenFactory = () => {
  return OidcStore.currentAccessToken;
};

export const updateAccessToken = token => {
  OidcStore.currentAccessToken = token ? token : null;
};
