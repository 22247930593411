import axios from "axios";
import Config from "../Config";
import Helpers from "../Helpers";
import { OPEN_VEHICLERETURN_BY_SEARCHPARAM, OPEN_VEHICLERETURN_BY_SEARCHPARAM_SUCCESS, OPEN_VEHICLERETURN_BY_SEARCHPARAM_FAILURE } from "./Search/SearchModule"

import { CHANGE_FEED_USER_MESSAGE_RECEIVED, CHANGE_FEED_MESSAGE_RECEIVED } from "./ChangeFeed/ChangeFeedModule";
import {
  VEHICLE_RETURNS_SUMMARIES_REQUESTED,
  VEHICLE_RETURNS_SUMMARIES_SUCCESS,
  VEHICLE_RETURNS_SUMMARIES_FAILURE,
  VEHICLE_RETURNS_SUMMARIES_DELETE_REQUESTED,
  VEHICLE_RETURNS_SUMMARIES_DELETE_SUCCESS,
  VEHICLE_RETURNS_SUMMARIES_DELETE_FAILURE
} from "./VehicleReturns/VehicleReturnsModule";
import {
  VEHICLE_RETURNS_SUMMARY_REQUESTED,
  VEHICLE_RETURNS_SUMMARY_SUCCESS,
  VEHICLE_RETURNS_SUMMARY_FAILURE
} from "./VehicleReturns/VehicleReturnModule";

const INITIAL_STATE = {
  isBusy: false,
  open: false
};

export const REPLAY_REQUESTED = "VEHICLE_RETURNS/REPLAY::REQUESTED";
export const REPLAY_SUCCESS = "VEHICLE_RETURNS/REPLAY::SUCCESS";
export const REPLAY_FAILED = "VEHICLE_RETURNS/REPLAY::FAILED";

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, { type, payload }) => {
  console.log(type);
  switch (type) {
    case CHANGE_FEED_MESSAGE_RECEIVED:
    case CHANGE_FEED_USER_MESSAGE_RECEIVED:
      return state;
    case VEHICLE_RETURNS_SUMMARIES_REQUESTED:
      return {
        ...state,
        isBusy: payload.isBusy === true
      };
    case VEHICLE_RETURNS_SUMMARIES_SUCCESS:
      return {
        ...state,
        isBusy: false
      };
    case VEHICLE_RETURNS_SUMMARIES_FAILURE:
      return {
        ...state,
        isBusy: false
      };
    case VEHICLE_RETURNS_SUMMARIES_DELETE_REQUESTED:
      return {
        ...state,
        isBusy: true
      };
    case VEHICLE_RETURNS_SUMMARIES_DELETE_SUCCESS:
      return {
        ...state,
        isBusy: false
      };
    case VEHICLE_RETURNS_SUMMARIES_DELETE_FAILURE:
      return {
        ...state,
        isBusy: false
      };
    case OPEN_VEHICLERETURN_BY_SEARCHPARAM:
      return {
        ...state,
        isBusy: true
      };
    case OPEN_VEHICLERETURN_BY_SEARCHPARAM_SUCCESS:
      return {
        ...state,
        isBusy: false
      };
    case OPEN_VEHICLERETURN_BY_SEARCHPARAM_FAILURE:
      return {
        ...state,
        isBusy: false
      };
    case VEHICLE_RETURNS_SUMMARY_REQUESTED:
      return {
        ...state,
        isBusy: true
      };
    case VEHICLE_RETURNS_SUMMARY_SUCCESS:
      return {
        ...state,
        isBusy: false
      };
    case VEHICLE_RETURNS_SUMMARY_FAILURE:
      return {
        ...state,
        isBusy: false
      };
    case REPLAY_REQUESTED:
      return {
        ...state,
        isBusy: true
      };
    case REPLAY_SUCCESS:
      return {
        ...state,
        isBusy: false
      };
    case REPLAY_FAILED:
      return {
        ...state,
        isBusy: false
      };
    default:
      return state;
  }
};

export const requestReplay = (byOwner = false) => dispatch => {
  console.log("requestReplay");
  var endpointUrl = Config.Endpoints.API_VEHICLE_RETURN.RequestReplay.format(
    { byOwner }
  );
  dispatch({
    type: REPLAY_REQUESTED,
    payload: {
      isBusy: true
    }
  });

  axios
    .post(endpointUrl, {}, {
      headers: {
        "Authorization": `Bearer ${Helpers.Oidc.accessTokenFactory()}`
      }
    })
    .then(response => {
      dispatch({
        type: REPLAY_SUCCESS,
        payload: {
          items: response.data
        }
      });
    })
    .catch(err => {
      dispatch({
        type: REPLAY_FAILED,
        payload: {
          error: err
        }
      });
    });
};