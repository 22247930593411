import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DisplayForLicensePlate from "../../../../Layout/Display/LicensePlateNumber";
import DisplayForVehicleIdentificationNumber from "../../../../Layout/Display/VehicleIdentificationNumber";
import { requestExpertise } from "../../../VehicleReturnModule";
import { LeaDate } from '../../../../../Helpers';

import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Divider,
  LinearProgress,
  Button
} from '@material-ui/core';

class ProcessSummary extends React.Component {
  constructor(props) {
    super(props);
    this.requestExpertise = this.requestExpertise.bind(this);
  }

  requestExpertise(event) {
    console.log("requestExpertise");
    event.preventDefault();
    const { requestExpertise, currentVehicleReturn } = this.props;
    requestExpertise(currentVehicleReturn.current.id);
  }

  render() {
    const { className, vehicleReturnData, showDocuments, classes } = this.props;
    const staticData = {
      progress: 45
    };
    return (
      <Card className={clsx(classes.root, className)}>
        <CardContent>
          <div className={classes.details}>
            <div>
              <Typography
                gutterBottom
                variant="h3"
              >
                <DisplayForLicensePlate type="de" value={vehicleReturnData.licensePlateNumber} />
              </Typography>
              <DisplayForVehicleIdentificationNumber value={vehicleReturnData.vehicleIdentificationNumber} useLabel={true} />
              <Typography
                className={classes.dateText}
                color="textSecondary"
                variant="body1"
              >
                Vertragsnummer: {vehicleReturnData.contractNumber}
              </Typography>
            </div>
          </div>
          <div className={classes.progress}>
            <Typography variant="body1">Prozessfortschritt: {staticData.progress}%</Typography>
            <LinearProgress
              value={staticData.progress}
              variant="determinate"
            />
            <Typography variant="body1">Vorgang eröffnet: {LeaDate.display(vehicleReturnData.processOpenedDate)}</Typography>
          </div>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            className={classes.uploadButton}
            color="primary"
            variant="text"

            onClick={() => showDocuments()}
          >
            Dokumente
        </Button><Button
            className={classes.uploadButton}
            color="primary"
            variant="text"
            onClick={this.requestExpertise}
            disabled={vehicleReturnData.isExpertiseRequested === true}
          >
            Gutachten beauftragen
        </Button>
          {/* <IconButton
            edge="start"
            className={classes.documentButton}
            color="inherit"
            aria-label="documents"
            onClick={() => showDocuments()}>
            <AttachmentIcon />
          </IconButton>
          <IconButton
            edge="start"
            className={classes.documentButton}
            color="inherit"
            aria-label="expertise"
            onClick={this.requestExpertise}
            disabled={vehicleReturnData.isExpertiseRequested === true}>
            <WebIcon />
          </IconButton> */}
        </CardActions>
      </Card>
    )
  };
};

ProcessSummary.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    ...state
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestExpertise
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessSummary);
