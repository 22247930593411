import React from "react";

import { Switch, Route } from "react-router-dom";

import Overview from "./Overview";
import VehicleReturn from "./VehicleReturn";

const Routes = ({ urlPath }) => (
  <Switch>
    <Route path={`${urlPath}/`} exact>
      <Overview />
    </Route>
    <Route path={`${urlPath}/:id`}>
      <VehicleReturn />
    </Route>
  </Switch>
);

export default Routes;
