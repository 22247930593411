import React, { Component } from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from "connected-react-router";
import userManager from "../../Oidc/userManager";

import Helpers from "../../Helpers";

class CallbackPage extends Component {
  render() {
    console.log(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`);
    console.log(window.location);
    // just redirect to '/' in both cases
    return (
      <CallbackComponent
        userManager={userManager()}
        successCallback={data => {
          console.log("success", data);
          Helpers.Oidc.updateAccessToken(data.access_token);
          this.props.dispatch(push("/"));
        }}
        errorCallback={error => {
          this.props.dispatch(push("/"));
          console.error(error);
        }}
      >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);
