import axios from "axios";

import Config from "../../Config";
import Helpers from "../../Helpers";

import { CHANGE_FEED_USER_MESSAGE_RECEIVED, CHANGE_FEED_MESSAGE_RECEIVED } from "../ChangeFeed/ChangeFeedModule";

export const VEHICLE_RETURNS_SUMMARY_REQUESTED = "VEHICLE_RETURNS/summary::requested";
export const VEHICLE_RETURNS_SUMMARY_SUCCESS = "VEHICLE_RETURNS/summary::success";
export const VEHICLE_RETURNS_SUMMARY_FAILURE = "VEHICLE_RETURNS/summary::failure";
export const VEHICLE_RETURNS_UPDATE_CONTRACTDATA_REQUESTED = "VEHICLE_RETURNS/contractdata::update";
export const VEHICLE_RETURNS_SUBMIT_CONTRACTDATA_REQUESTED = "VEHICLE_RETURNS/contractdata::submit";
export const VEHICLE_RETURNS_REQUESTEXPERTISE_REQUESTED = "VEHICLE_RETURNS/requestexpertise::requested";
export const VEHICLE_RETURNS_REQUESTEXPERTISE_FAILURE = "VEHICLE_RETURNS/requestexpertise::failure";
export const VEHICLE_RETURNS_UPDATE_VEHICLELOCATION_REQUESTED = "VEHICLE_RETURNS/vehiclelocation::requested";
export const VEHICLE_RETURNS_SUBMIT_VEHICLELOCATION_REQUESTED = "VEHICLE_RETURNS/vehiclelocation::submit";
export const VEHICLE_RETURNS_SUBMIT_VEHICLELOCATION_FAILURE = "VEHICLE_RETURNS/vehiclelocation::failure";

const INITIAL_STATE = {
  isBusy: false,
  isDone: false,
  isError: false,
  error: null,
  current: {}
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHANGE_FEED_USER_MESSAGE_RECEIVED:
      if (
        state.current &&
        payload.message &&
        payload.message.type === "VehicleReturnSummary" &&
        payload.message.payload.vehicleReturnId ===
        state.current.vehicleReturnId
      ) {
        return {
          ...state,
          current: {
            ...state.current,
            ...payload.message.payload
          }
        };
      }

      return state;
    case VEHICLE_RETURNS_SUMMARY_REQUESTED:
      return {
        ...state,
        isBusy: payload.isBusy === true,
        isDone: false,
        isError: false,
        error: null
      };
    case VEHICLE_RETURNS_SUMMARY_SUCCESS:
      return {
        ...state,
        isBusy: false,
        isDone: true,
        isError: false,
        error: null,
        current: { ...payload.data }
      };
    case VEHICLE_RETURNS_SUMMARY_FAILURE:
      return {
        ...state,
        isBusy: false,
        isDone: true,
        isError: true,
        error: payload.error,
        current: {}
      };
    case VEHICLE_RETURNS_UPDATE_CONTRACTDATA_REQUESTED:
      return {
        ...state,
        current: { ...payload.data }
      }
    case VEHICLE_RETURNS_SUBMIT_CONTRACTDATA_REQUESTED:
    case VEHICLE_RETURNS_REQUESTEXPERTISE_REQUESTED:
      return state
    case CHANGE_FEED_MESSAGE_RECEIVED:
      switch (payload.message.feed) {
        case "VehicleReturnOpened":
        case "ContractDataChanged":
        case "VehicleDataChanged":
        case "ProcessDeleted":
        case "EditorChanged":
          console.log(payload.message.feed + " omitted, reuse in later development");
          return state;
        case "ExpertiseRequested":
          console.log("ExpertiseRequested updating state", state);
          return { ...state, current: { ...state.current, isExpertiseRequested: true } };
        default:
          return state;
      }
    case VEHICLE_RETURNS_UPDATE_VEHICLELOCATION_REQUESTED:
      console.log(VEHICLE_RETURNS_UPDATE_VEHICLELOCATION_REQUESTED, state);
      return {
        ...state,
        current: {...state.current, vehicleLocationData: { ...payload.data } }
      };
    case VEHICLE_RETURNS_SUBMIT_VEHICLELOCATION_REQUESTED:
      return state;
    default:
      return state;
  }
};

export const submitContractData = () => dispatch => {
  dispatch({
    type: VEHICLE_RETURNS_SUBMIT_CONTRACTDATA_REQUESTED,
    payload: {}
  });
}

export const updateContractData = contractData => dispatch => {
  dispatch({
    type: VEHICLE_RETURNS_UPDATE_CONTRACTDATA_REQUESTED,
    payload: { data: contractData }
  });
};

export const requestExpertise = vehicleReturnId => dispatch => {
  var endpointUrl = Config.Endpoints.API_VEHICLE_RETURN.RequestExpertise.format(
    { id: vehicleReturnId }
  );

  dispatch({
    type: VEHICLE_RETURNS_REQUESTEXPERTISE_REQUESTED,
    payload: {}
  });
  axios
    .post(endpointUrl, {}, {
      headers: {
        "Authorization": `Bearer ${Helpers.Oidc.accessTokenFactory()}`
      }
    })
    .then(data => {
      if ([200, 202].indexOf(data.status) < 0) {
        dispatch({
          type: VEHICLE_RETURNS_REQUESTEXPERTISE_FAILURE,
          payload: {
            isError: true,
            isSuccess: false,
            isSearching: false,
            reason: "Non-Success StatusCode"
          }
        });
      }
    });
}

export const submitVehicleLocationData = () => dispatch => {
  dispatch({
    type: VEHICLE_RETURNS_SUBMIT_VEHICLELOCATION_REQUESTED,
    payload: { }
  });
}

export const updateVehicleLocationData = vehicleLocationData => dispatch => {
  dispatch({
    type: VEHICLE_RETURNS_UPDATE_VEHICLELOCATION_REQUESTED,
    payload: { data: vehicleLocationData }
  });
};

export const getVehicleReturnSummary = vehicleReturnId => dispatch => {
  var endpointUrl = Config.Endpoints.API_VEHICLE_RETURN.ProcessDetails.format(
    { id: vehicleReturnId }
  );
  dispatch({
    type: VEHICLE_RETURNS_SUMMARY_REQUESTED,
    payload: {}
  });

  axios
    .get(
      endpointUrl,
      // { byOwner: false },
      {
        headers: {
          Authorization: `Bearer ${Helpers.Oidc.accessTokenFactory()}`
        }
      }
    )
    .then(response => {
      dispatch({
        type: VEHICLE_RETURNS_SUMMARY_SUCCESS,
        payload: {
          data: { ...response.data, vehicleLocationData: { description: 'Auf dem Hofe', adress: 'Waldweg 1', zip: '12345', city: 'Dorf' } }
        }
      });
    })
    .catch(err => {
      dispatch({
        type: VEHICLE_RETURNS_SUMMARY_FAILURE,
        payload: {
          error: err
        }
      });
    });
};
