import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import { LeaDate } from '../../../Helpers';

import { useStyles } from '../../theme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Typography } from '@material-ui/core';

export default function FullWidthGrid(props) {
  const classes = useStyles();
  const {
    items,
    editItemAction,
    deleteItemAction
  } = props;
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow key={"overview"}>
              <TableCell >Vertragsnummer</TableCell >
              <TableCell >FIN</TableCell >
              <TableCell >Kennzeichen</TableCell >
              <TableCell >Leasingnehmer</TableCell >
              <TableCell >Status</TableCell >
              <TableCell >Bearbeiter</TableCell >
              <TableCell >Händler</TableCell >
              <TableCell >Löschen</TableCell >
              <TableCell >Details</TableCell >
            </TableRow>
          </TableHead>

          <TableBody>{
            items.map(row => (
              <OverviewRow key={row.processUid} process={row} deleteProcess={deleteItemAction} editItem={editItemAction} />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </div>
  );
}

class OverviewRow extends Component {
  render() {
    const process = this.props.process;
    return (
      <TableRow hover key={process.processUid}>
        <TableCell >          
          <Typography variant="body1" display="block">{process.contractNumber}</Typography>
          <Typography variant="caption" display="block">{LeaDate.display(process.processOpenedDate)}</Typography>
          <Typography variant="caption" display="block">{process.processUid}</Typography>
        </TableCell >
        <TableCell >{process.vehicleIdentificationNumber}</TableCell >
        <TableCell >{process.licensePlateNumber}</TableCell >
        <TableCell >{process.lesseeName}</TableCell >
        {(process.isExpertiseRequested === true && (
          <TableCell ><Typography variant="body2">{process.state}</Typography>
            <Typography variant="caption">{"(" + LeaDate.display(process.expertiseRequestedDate) + ")"}</Typography></TableCell >
        )) || <TableCell >{process.state}</TableCell >}
        <TableCell >{process.editorId}</TableCell >
        <TableCell >{process.retailerCompanyName}</TableCell >

        <TableCell><IconButton aria-label="delete" onClick={() => { this.props.deleteProcess(process.processUid); }}>
          <DeleteIcon />
        </IconButton>
        </TableCell>
        <TableCell><IconButton aria-label="edit" onClick={() => { this.props.editItem(process.processUid); }}>
          <EditIcon />
        </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}
