import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as oidcReducer } from "redux-oidc";

import SearchReducer from "../App/Search/SearchModule";
import ChangeFeedReducer from "../App/ChangeFeed/ChangeFeedModule";

import EventFeedReducer from "../App/EventFeed/EventFeedModule";

import VehicleReturnsReducer from "../App/VehicleReturns/VehicleReturnsModule";
import VehicleReturnReducer from "../App/VehicleReturns/VehicleReturnModule";
import AppReducer from "../App/AppModule";

export default history => {
  return combineReducers({
    router: connectRouter(history),
    oidc: oidcReducer,
    search: SearchReducer,
    changeFeed: ChangeFeedReducer,
    eventFeed: EventFeedReducer,
    currentVehicleReturn: VehicleReturnReducer,
    vehicleReturns: VehicleReturnsReducer,
    app: AppReducer
  });
};
