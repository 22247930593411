import React from "react";
import { Switch, Route } from "react-router-dom";

import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// import "./App.css";
import AppBar from "./AppBar";
import Stage from "./Layout/Stage";

import Main from "./Main";
import ChangeFeedHost from "./ChangeFeed";

import { useStyles } from './theme';

import Callback from "./Login/Callback";

const IsBusy = ({ isBusy }) => {
  const classes = useStyles();
  return (
    (isBusy === true && (
      <div className={classes.busyAnimation}>
        <CircularProgress/>
      </div>
    )) ||
    null
  );
};

function App(props) {
  const body = (
    <Switch>
      <Route path="/authcallback">
        <Callback />
      </Route>
      <Route path="/">
        <Main />
      </Route>
    </Switch>
  );
  const {
    isBusy
  } = props;

  const sidebar = <aside>NOTIFICATIONS</aside>;

  return (
    <Container>
      <IsBusy isBusy={isBusy} />
      <ChangeFeedHost />
      <AppBar />
      <Stage body={body} sidebar={sidebar} />
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    isBusy: state.app.isBusy
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
