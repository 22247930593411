import axios from "axios";

const baseConfig = (process.env.NODE_ENV==="production") ? window.__learoy__config : window.__learoy__configDev;

let Config = {
  $initialized: false,
  Endpoints: {},
  version: "0.0.1"
};

const parseEndpoints = async endpoints => {
  const result = {
    $_isParsed: true
  };

  for (let configKey in endpoints) {
    if (!endpoints.hasOwnProperty(configKey)) {
      continue;
    }

    const {$root, $definition, ...cleaned} = endpoints[configKey];

    result[configKey] = cleaned;

    const current = endpoints[configKey];

    if (current.$definition && current.$root) {
      try
      {
        const definition = await axios.get(
          `${current.$root}${current.$definition}`
        );  
        const { paths } = definition.data;

        for (let path in paths) {
          result[configKey] = {
            ...result[configKey],
            ...parseEndpointPath(`${current.$root}${path}`, paths[path])
          };
        }
      }
      catch(ex) {
        if(process.env.NODE_ENV === "development") {
          console.error(ex);
        } 
        else {
          throw ex;
        }
      }
    }
  }
  
  return Promise.resolve(result);
};

const parseEndpointPath = (pattern, verbs) => {
  let result = {};

  const format =
    pattern.indexOf("{") < 0
      ? () => pattern
      : values => {
          if (!values) {
            return pattern;
          }

          let url = pattern;

          for (var key in values) {
            if (!values.hasOwnProperty(key)) {
              continue;
            }

            url = url.replace(`{${key}}`, values[key]);
          }

          return url;
        };

  for (let verb in verbs) {
    if (!verbs.hasOwnProperty(verb)) {
      continue;
    }
    const opts = verbs[verb];
    if (opts.operationId) {
      result[opts.operationId] = {
        url: pattern,
        format,
        verb
      };
    }
  }

  return result;
};

export const init = callback => {
  if(Config.$initialized) {
    callback(Config);
  }
  if (baseConfig.Endpoints) {
    parseEndpoints(baseConfig.Endpoints).then(parsedConfig => {
      Config.Endpoints = {
        ...Config.Endpoints,
        ...parsedConfig
      };
      if (typeof callback === "function") {
        callback(Config);
      }
    });
  }
  Config.version = require('../../package.json').version;
  console.log("VERSION: ", Config.version);

};

export default Config;
