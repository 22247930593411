import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import VehicleReturns from "./VehicleReturns";
import EventFeed from "./EventFeed";

const Routes = () => (
  <Switch>
    <Route path="/vehiclereturn/open">
      <div>
        {/* <Search /> */}
        <code>OLD</code>
      </div>
    </Route>
    <Route path="/vehiclereturns/">
      <VehicleReturns urlPath="/vehiclereturns" />
      <EventFeed />
    </Route>
    <Redirect path="/" to="/vehiclereturns" />
  </Switch>
);

export default Routes;
