import React from "react";
import { connect } from "react-redux";

import Routes from "./Routes";

import Login from "./Login";

const AuthContent = () => (
  <div>
    <section>
      <Routes />
    </section>
  </div>
);

const Main = ({ user }) => (
  <React.Fragment>
    {((process.env.NODE_ENV === "production") && (!user || user.expired) && <Login />) || <AuthContent />}
  </React.Fragment>
);

const mapStateToProps = state => ({
  user: state.oidc.user
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
