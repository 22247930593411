import axios from "axios";

import Config from "../../Config";
import Helpers from "../../Helpers"

export const OPEN_VEHICLERETURN_BY_SEARCHPARAM =
  "search/OPEN_VEHICLERETURN_BY_SEARCHPARAM::START";
export const OPEN_VEHICLERETURN_BY_SEARCHPARAM_SUCCESS =
  "search/OPEN_VEHICLERETURN_BY_SEARCHPARAM::SUCCESS";
export const OPEN_VEHICLERETURN_BY_SEARCHPARAM_IGNORED =
  "search/OPEN_VEHICLERETURN_BY_SEARCHPARAM::FAILURE";
export const OPEN_VEHICLERETURN_BY_SEARCHPARAM_FAILURE =
  "search/OPEN_VEHICLERETURN_BY_SEARCHPARAM::FAILURE";

const initialState = {
  searchParam: "",
  isSearching: false,
  isSuccess: false,
  isError: false,
  reason: null,
  last: {
    vehicleReturnId: null
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_VEHICLERETURN_BY_SEARCHPARAM:
      return { ...state, ...payload };

    case OPEN_VEHICLERETURN_BY_SEARCHPARAM_SUCCESS:
      return { ...state, ...payload };

    case OPEN_VEHICLERETURN_BY_SEARCHPARAM_FAILURE:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export const openVehicleReturnBySearchParam = searchParam => dispatch => {
  var endpointUrl = Config.Endpoints.API_VEHICLE_RETURN.OpenVehicleReturn.format();

  if (!searchParam || typeof searchParam.toUpperCase !== "function") {
    dispatch({
      type: OPEN_VEHICLERETURN_BY_SEARCHPARAM_IGNORED,
      payload: {
        isError: false,
        isSuccess: false,
        isSearching: false,
        reason: "Invalid Search Parameter"
      }
    });
  }

  searchParam = searchParam.toUpperCase();

  dispatch({
    type: OPEN_VEHICLERETURN_BY_SEARCHPARAM,
    payload: {
      searchParam,
      isSearching: true,
      isSuccess: false,
      isError: false,
      reason: null,
      isBusy: true,
      last: {
        vehicleReturnId: null
      }
    }
  });

  axios
    .post(endpointUrl, {
      searchParameter: searchParam,
    }, {
      headers: {
        "Authorization": `Bearer ${Helpers.Oidc.accessTokenFactory()}`
      }
    })
    .then(data => {
      console.log("Response: ", data);
      if ([200, 202].indexOf(data.status) < 0) {
        dispatch({
          type: OPEN_VEHICLERETURN_BY_SEARCHPARAM_FAILURE,
          payload: {
            isBusy: false,
            isError: true,
            isSuccess: false,
            isSearching: false,
            reason: "Non-Success StatusCode"
          }
        });
      }

      if (data.status === 202 && data.data) {
        console.warn("pending", data.data);
        dispatch({
          type: OPEN_VEHICLERETURN_BY_SEARCHPARAM_SUCCESS,
          payload: {
            isBusy: false,
            isError: false,
            isSuccess: true,
            isSearching: false,
            reason: null,
            last: {
              vehicleReturnId: data.data.vehicleReturnId,
              isExisting: false
            }
          }
        });
      } else if (
        data.status === 200 &&
        data.data &&
        data.data.aggregateId
      ) {
        // console.info("existing", data.data);
        dispatch({
          type: OPEN_VEHICLERETURN_BY_SEARCHPARAM_SUCCESS,
          payload: {
            isBusy: false,
            isError: false,
            isSuccess: true,
            isSearching: false,
            reason: null,
            last: {
              vehicleReturnId: data.data.aggregateId,
              isExisting: true
            }
          }
        });
      }
    })
    .catch(err => {
      dispatch({
        type: OPEN_VEHICLERETURN_BY_SEARCHPARAM_FAILURE,
        payload: {
          isBusy: false,
          isError: true,
          isSuccess: false,
          isSearching: false,
          reason: err
        }
      });
    });
};
